/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-02-27",
    versionChannel: "nightly",
    buildDate: "2024-02-27T00:06:00.998Z",
    commitHash: "12151f51251d6b8dfd4df94467475465c8cfb810",
};
